import "./Reset.scss";
import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.scss';
import { DateTime } from "luxon";
import { Outlet, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import ModalHandler from "./components/modals/ModalHandler";
import { CookieProvider } from "./hooks";
import { TranslationProvider } from "./hooks/useTranslations";

import ENV from "./utils/environments";
import Utils from "./utils/Utils";
import {flushChannel, getChannel} from "./store/channel/channelReducer";
import {setAnnouncements, setCurrentFlow} from "./store/app/appReducer";
import {useAppDispatch, useAppSelector} from "./store";
import {OptionsProvider} from "./hooks/useOptions";
import Header from './layouts/Header'
import Footer from './layouts/Footer';

import Spinner from "./components/Spinner";

const queryToObject = (query: string): Record<string, string> => {
  return query.split(',').reduce((acc: Record<string, string>, pair: string) => {
    const [key, value] = pair.split('=');
    acc[key] = value;
    return acc;
  }, {});
};

const decodeToken = (token: string) => {
  try {
		const decodedQuery = decodeURIComponent(window.atob(token));
		const decoded = queryToObject(decodedQuery);

    return decoded;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

function App() {
  /**
   * The App function governs the underlying behaviours of the webfunnel.
   *
   * Namely, it handles the default state of the application in regard to channel properties,
   * analytics and language.
   */
  const dispatch = useAppDispatch();

  const channel = useAppSelector((state) => state.channel)
  const language = useAppSelector((state) => state.app.currentLanguage || state.channel.properties.language.meta.default)

  const rootRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  const flow = useAppSelector((state) => state.app.currentFlow)
  const isChannelAvailable = useMemo(() => channel.id !== undefined && channel.id !== -1, [channel])
  const ready = useMemo(() => isChannelAvailable && !!flow, [isChannelAvailable, flow])

  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

	const shouldRedirect = useMemo(() => {
    const params = Object.fromEntries(searchParams);
    if ('token' in params) {
      const decoded = decodeToken(params.token);
      // If a token exists and contains an email, don't redirect
      return decoded && !decoded.email;
    }
    return false;
  }, [searchParams]);

  useEffect(() => {
    if (shouldRedirect)
			navigate({pathname: "/summary", search: `?${createSearchParams(Object.fromEntries(searchParams))}`})
  }, [navigate, searchParams, shouldRedirect])

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}
	
		if (channel.error) {
			toast.error(channel.error, { autoClose: false });
		}
	}, [channel.error, isChannelAvailable, channel.loading]);

  useLayoutEffect(() => {
    if (!isChannelAvailable) return;

    if (!flow) dispatch(setCurrentFlow(channel.properties.switches.flow.default))
  }, [isChannelAvailable, channel, flow, dispatch])

  useLayoutEffect(() => {
    /**
     * Handles resize and scrolling events.
     */
    // Scrolling
    const onScroll = () => {
      const scrolling = window.scrollY > 0
      if (scrolling) rootRef.current?.setAttribute('data-scrolling', 'true');
      else rootRef.current?.removeAttribute('data-scrolling')
    }
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    }

  }, [])

  useLayoutEffect(() => {
    /**
     * Handle RTL formatting by adding an attribute to the document body whenever an RTL language is selected.
     */
    language === "ar" ? document.body.setAttribute("dir", "rtl") : document.body.setAttribute("dir", "ltr");
  }, [language])

  useLayoutEffect(() => {
    /**
     * Extract the channel name from the URL and set the correct channel favicon
     */
    // Setting channel name and favicon
    const favicon = document.getElementById("favicon") as HTMLAnchorElement;
    const title = document.getElementById("title") as HTMLElement;

    if (channel.name.trim() !== "") {
      title.innerHTML = Utils.string.capitalize(channel.name);
      /**
       * Favicon names:
       * for multi work channel names
       * space is changed for underscore
       */
      const faviconName = channel.name.toLowerCase().replace(" ", "_");
      if (favicon) favicon.href = `${faviconName}.favicon.ico`
    }
  }, [channel.name])

  useLayoutEffect(() => {
    /**
     * Prepare the application for consumption by requesting the channel data from the server.
     *
     * If current channel properties are not older than 24 hours, don't
     * make a new call to the API
     *
     * If the channel properties are kept, we set the channel colours.
     */

    if (
      channel.timestamp !== undefined
      && DateTime.now() >= DateTime.fromISO(channel.timestamp)
    ) {
      dispatch(flushChannel());
    } else if (channel.id === -1) {
      dispatch(getChannel());
    } else {
      Utils.layout.setChannelVariables(channel.properties.whitelabel.variables)
      channel.properties.announcements.length && dispatch(setAnnouncements(channel.properties.announcements))
    }
  }, [dispatch, channel])

  if (ENV.BASE_PATH !== '/' && window.location.pathname === '/') {
    /**
     * Redirecting to correct path in case the
     * path is empty when it shouldn't be.
     */
    window.location.replace(ENV.BASE_PATH);
  }

	

  if (!ready) return <Spinner />

  return (
    <div className={styles.app} ref={rootRef}>
      <div className={styles.content}>
        <TranslationProvider>
          <CookieProvider>
            <Header />
						<OptionsProvider>
							<Outlet />
						</OptionsProvider>
            <Footer />
            <ModalHandler channel={channel} />
          </CookieProvider>
          <ToastContainer />
        </TranslationProvider>
      </div>
    </div>
  );
}

export default App;
